<template>
  <section class="cook-links" :class="{ 'cook-links__catalog': isCatalog }">
    <div class="container-padding">
      <div class="cook-links__content">
        <a v-for="(item, i) in list" :key="i" :href="item.link" target="_blank" class="cook-links__item">
          <ImgComponent class="cook-links__img" :img="item.img" width="200" height="200" />
          <span class="cook-links__title">{{ item.title }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "CookLinksComponent",
  components: { ImgComponent },
  props: {
    list: {
      type: Array,
    },
    isCatalog: Boolean,
  },
};
</script>

<style lang="stylus">
.cook-links {
  padding: 16px 0
  background var(--white-3)

  &__catalog {
    padding 48px 0

    +below(1080px) {
      padding: 20px 0
    }
  }

  &__content {
    display flex
    gap 24px
    justify-content space-between

    +below(1080px) {
      gap 18px
      overflow auto
    }
  }

  &__item {
    display flex
    flex-direction column
    align-items center
    gap 12px

    +below(1080px) {
      gap 6px
      min-width 80px
    }
  }

  &__item:hover &__title {
    color var(--main)
  }

  &__img {
    mix-blend-mode: darken;
    width: 100px;
    height: 100px;
    object-fit: contain;

    +below(980px) {
      width: 80px;
      height: 80px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1rem
    line-height: 22px;
    text-align: center;
    color var(--gray-dark)
    transition var(--transition)

    +below(1080px) {
      font-size 0.725rem
    }
  }
}
</style>
