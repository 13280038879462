<template>
  <div class="home-welcome">
    <SliderComponent
      :slider-options="sliderOptions"
      :items="banners"
      v-slot="{ item }"
      hide-arrows
      class="home-welcome__slider"
      v-if="banners && banners.length"
    >
      <a class="home-welcome__slide" :href="item.link" :aria-label="item.title">
        <ImgComponent :img="item.img" :loader="false" width="2000" height="1000" />
      </a>
      <!--      <img class="home-welcome__img" :src="item.img" alt="" />-->
      <!--      <div class="container">-->
      <!--        <div class="home-welcome__content">-->
      <!--          <span class="home-welcome__title">{{ item.title }}</span>-->
      <!--          <span class="home-welcome__subtitle">{{ item.description }}</span>-->
      <!--          <a :href="item.link" target="_blank" class="btn btn&#45;&#45;md btn&#45;&#45;white home-welcome__btn">-->
      <!--            <span>Сделать заказ</span>-->
      <!--            <RemixIconComponent category="system" name="arrow-right-line" />-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <a :href="item.link" target="_blank">-->
      <!--        <ImgComponent :img="item.img" :alt="item.img.alt" />-->
      <!--      </a>-->
    </SliderComponent>
    <CookLinksComponent :list="cook_links" />
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import CookLinksComponent from "components/CookLinksComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "HomeWelcomeComponent",
  components: {
    ImgComponent,
    CookLinksComponent,
    SliderComponent,
  },
  props: {
    banners: {
      type: Array,
    },
    cook_links: {
      type: Array,
    },
  },
  data() {
    return {
      sliderOptions: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 4000,
        },
      },
      // data: [
      //   {
      //     img: "/static/images/welcome-bg.png",
      //     title: "Всё в твоих руках",
      //     subtitle:
      //       "Больше 4000 товаров сети супермаркетов «Зелёное Яблоко» доступен тебе на сайте и в мобильном приложение с бесплатной доставкой на дом!",
      //   },
      //   {
      //     img: "/static/images/welcome-bg-2.png",
      //     title: "Всё в твоих руках",
      //     subtitle:
      //       "Больше 4000 товаров сети супермаркетов «Зелёное Яблоко» доступен тебе на сайте и в мобильном приложение с бесплатной доставкой на дом!",
      //   },
      //   {
      //     img: "/static/images/welcome-bg-3.png",
      //     title: "Всё в твоих руках",
      //     subtitle:
      //       "Больше 4000 товаров сети супермаркетов «Зелёное Яблоко» доступен тебе на сайте и в мобильном приложение с бесплатной доставкой на дом!",
      //   },
      // ],
    };
  },
};
</script>

<style lang="stylus">
.home-welcome{
  position: relative;

  .slider{
    padding: 0

    &__items{
      max-width 100%
    }
  }

  &__slider{
    max-width 100%
    //height 567px

    .swiper-slide{
      background black
    }

    & .container{
      display flex
      justify-content flex-end
      position: absolute;
      bottom: 179px
    }
  }

  &__slide {
    width 100%
    height 100%

    img {
      height 100%
    }
  }

  &__img{
    width 100%
    opacity .7
    height 100%
    //position: absolute;
    object-fit cover
  }

  &__content{
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    max-width: 450px;
    text-align: start;
    margin-right 200px
  }

  &__title{
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    color var(--white)
  }

  &__subtitle{
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color var(--white)
  }

  &__btn{
    margin-top: 32px
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    gap 4px !important
  }
}
</style>
