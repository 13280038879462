<template>
  <main class="page home-page">
    <div class="page__inner">
      <HomeWelcomeComponent :banners="banners" v-if="banners && banners.length" :cook_links="cook_links" />
      <SectionComponent v-if="showDiscountProducts">
        <SliderComponent
          id="shares"
          class="home-page__sale-slider"
          :items="discount_products.data"
          v-slot="{ item, grid }"
          :slider-options="sliderSaleOptions"
          title="Акции"
          :route="{ name: 'category-open', query: { discount: true } }"
          button-title="Все товары"
        >
          <CardComponent :data="item" :grid="grid" />
        </SliderComponent>
        <SliderComponent
          v-for="(group, i) in stocks_cooking_groups"
          :key="i"
          class="home-page__banners-slider"
          :items="group.products"
          v-slot="{ item }"
          :slider-options="sliderOptions"
          :title="group.title"
          :route="{ name: 'category-open', query: { product_group_id: group.id } }"
          button-title="Все товары"
        >
          <CardComponent :data="item" />
        </SliderComponent>
      </SectionComponent>
      <CookingSliderComponent :data="cook_products" />
      <SectionComponent v-if="cooking_sets_groups && cooking_sets_groups.length">
        <SliderComponent
          v-for="(group, i) in cooking_sets_groups"
          :key="i"
          class="home-page__banners-slider"
          :items="group.products"
          v-slot="{ item }"
          :slider-options="sliderOptions"
          :title="group.title"
          :route="{ name: 'category-open', query: { product_group_id: group.id } }"
          button-title="Все товары"
        >
          <CardComponent :data="item" />
        </SliderComponent>
      </SectionComponent>
      <SetSliderComponent :data="productSets" />
      <SectionComponent v-if="after_sets_groups && after_sets_groups.length">
        <SliderComponent
          v-for="(group, i) in after_sets_groups"
          :key="i"
          class="home-page__banners-slider"
          :items="group.products"
          v-slot="{ item }"
          :slider-options="sliderOptions"
          :title="group.title"
          :route="{ name: 'category-open', query: { product_group_id: group.id } }"
          button-title="Все товары"
        >
          <CardComponent :data="item" />
        </SliderComponent>
      </SectionComponent>
      <SectionComponent>
        <HomeBannersComponent />
        <HomeHelpComponent />
      </SectionComponent>
    </div>
  </main>
</template>

<script>
import SectionComponent from "components/SectionComponent.vue";
import HomeWelcomeComponent from "./components/HomeWelcomeComponent.vue";
import HOME_PAGE_LANDING from "@/graphql/pages/landing/HomePage.graphql";
import HOME_PAGE_STORE from "@/graphql/pages/store/HomePage.graphql";
import { moreButtonTitle } from "@/utils";

export default {
  name: "HomePage",
  async mounted() {
    let token = this.$store.state.auth_token;
    let promisses = [];
    promisses.push(
      this.$apollo.provider.clients.landing
        .query({
          query: HOME_PAGE_LANDING,
        })
        .then(({ data }) => {
          this.$store.state.home_page.banners = data.banners_paginate.data;
          this.$store.state.home_page.cook_links = data.cook_links;
          this.$store.state.home_page.hot_links = data.hot_links;
        })
    );
    promisses.push(
      this.$apollo.provider.clients.store
        .query({
          query: HOME_PAGE_STORE,
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.home_page.product_sets = data.product_sets_paginate?.data;
          this.$store.state.home_page.stocks_cooking_groups = data.stocks_cooking_groups;
          this.$store.state.home_page.cooking_sets_groups = data.cooking_sets_groups;
          this.$store.state.home_page.after_sets_groups = data.after_sets_groups;
          this.$store.state.home_page.cook_products = data.cooking_products.data;
          this.$store.state.home_page.discount_products = data.discount_products;
        })
    );
    await Promise.all(promisses);
  },
  data() {
    return {
      first: 10,
      sliderSaleOptions: {
        slidesPerView: 1.1,
        spaceBetween: 10,
        grid: {
          rows: 2,
        },
        breakpoints: {
          540: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 2.1,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      },
      sliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 10,
        breakpoints: {
          390: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          500: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          620: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  computed: {
    discount_products() {
      return this.$store.state.home_page.discount_products;
    },
    stocks_cooking_groups() {
      return this.$store.state.home_page.stocks_cooking_groups;
    },
    cooking_sets_groups() {
      return this.$store.state.home_page.cooking_sets_groups;
    },
    after_sets_groups() {
      return this.$store.state.home_page.after_sets_groups;
    },
    banners() {
      return this.$store.state.home_page.banners;
    },
    productSets() {
      return this.$store.state.home_page.product_sets;
    },
    cook_links() {
      return this.$store.state.home_page.cook_links;
    },
    cook_products() {
      return this.$store.state.home_page.cook_products;
    },
    showDiscountProducts() {
      return (
        (this.stocks_cooking_groups && this.stocks_cooking_groups.length) ||
        (this.discount_products &&
          this.discount_products.paginatorInfo &&
          this.discount_products.paginatorInfo.total)
      );
    },
  },
  methods: {
    moreButtonTitle,
  },
  components: {
    HomeWelcomeComponent,
    SectionComponent,
    CookingSliderComponent: () => import("./components/CookingSliderComponent.vue"),
    SetSliderComponent: () => import("./components/SetSliderComponent.vue"),
    SliderComponent: () => import("components/SliderComponent.vue"),
    HomeHelpComponent: () => import("./components/HomeHelpComponent.vue"),
    HomeBannersComponent: () => import("./components/HomeBannersComponent.vue"),
    CardComponent: () => import("components/CardComponent.vue"),
  },
  metaInfo: {
    title: "Зеленое яблоко",
    titleTemplate: null,
  },
};
</script>

<style lang="stylus">
.home-page {
  padding-top 0
}

.home-page__sale-slider {
  height 472px
  +below(1200px) {
    height 420px
  }
}
</style>
